

































import { Component, Mixins } from 'vue-property-decorator';
import ConfirmationMixin from '@/mixins/http/ConfirmationMixin';
import DisplayErrors from '@/components/DisplayErrors.vue';
import { RequestData } from '@/store/types/RequestStore';
import { RegisterHttp } from '@/utils/Decorators';
import { showErrorToast, showToast } from '@/utils/Toast';
import { easync } from '@/utils/http';

@Component({ components: { DisplayErrors } })
export default class Confirmation extends Mixins(ConfirmationMixin) {
  private isValid = false;
  private password = '';

  @RegisterHttp('confirmation') http: RequestData;

  get confirmationToken() {
    return this.$route.query.confirmation_token as string;
  }

  async created() {
    const [data, errors] = await easync(
      this.validateToken(this.confirmationToken)
    );

    if (errors) {
      showToast(`C'è stato qualche problema con l'attivazione dell'utente`, {type: 'error'});
      this.isValid = false;
      return;
    }

    this.isValid = true;
  }

  async submit() {
    const [data, errors] = await easync(
      this.confirmWithPassword(this.confirmationToken, this.password)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    showToast(`Utente attivato correttamente. Ora accedi!`);
    this.$router.push('/login');
  }
}
