import { httpStore } from '@/store/typed';
import { BASE_API_URL } from '@/utils/Constants';
import qs from 'qs';
import { Component, Vue } from 'vue-property-decorator';

const BASE_CONFIRMATION_URL = BASE_API_URL+"confirmation"


@Component
export default class ConfirmationMixin extends Vue {

  validateToken(confirmationToken: string) {
    const url = `${BASE_CONFIRMATION_URL}/validate?confirmationToken=${confirmationToken}`

    return httpStore.request({
      tag: 'confirmation',
      url: url,
      method: 'GET'
    })
  }

  confirmWithPassword(confirmationToken: string, password: string): Promise<any> {

    const data = {confirmationToken, password};

    const query =  qs.stringify(data, { arrayFormat: "brackets" })
    const url = `${BASE_CONFIRMATION_URL}/confirmations/with_password?${query}`

    return httpStore.request({
      tag: 'confirmation',
      url: url,
      data: { confirmationToken, password },
      method: 'POST'
    })
  }
}
